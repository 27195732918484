import { Navbar } from "@/components/navbar";
import { AccessControlMetrics } from "./components/access-control-metrics/access-control-metrics";
import { CardHoldersAndAccessProfileTab } from "./components/card-holders-and-access-profile-tab/card-holders-and-access-profile-tab";

export const AccessControl = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(/images/--gf-dashboard-cover.png)",
          backgroundSize: "100% 270px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container -mb-10 flex flex-col gap-5 bg-cover px-4 pt-4 md:px-9 md:pt-9 2xl:gap-11">
          <Navbar title="Access Control" />
          <div className="">
            <AccessControlMetrics />
          </div>
        </div>
      </div>
      <div className="container relative mt-16">
        <CardHoldersAndAccessProfileTab />
      </div>
    </div>
  );
};

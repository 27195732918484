import { DeltaType } from "@/common/constants/enums";
import { Navbar } from "@/components/navbar";
import { OverviewCard } from "@/components/overview-card";
import { PageLoader } from "@/components/page-loader";
import { isValidDeltaType } from "@/lib/helpers/delta-type";
import {
  useGetChargerLocationsQuery,
  useGetKwhTodayQuery,
  useGetTodayChargingSessionQuery,
  useGetTodayRevenueQuery,
  useGetTotalChargersQuery,
  useGetTotalUsageTodayQuery,
} from "@/redux/features/home/home.api";
import {
  setChargerLocations,
  setChargingSession,
  setKwhToday,
  setTotalChargers,
  setTotalRevenue,
  setTotalUsageToday,
  startLoading,
  stopLoading,
} from "@/redux/features/home/home.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useEffect } from "react";

import { ChargerLocations } from "./components/charger-locations";
import { KwhGraph } from "./components/kwh-graph";
import { SalesChart } from "./components/sales-chart";
import { TotalChargers } from "./components/total-chargers";
import { TotalRevenueCard } from "./components/total-revenue-card";
import { Traffic } from "./components/traffic";
import { UtilizationRatio } from "./components/utilization-ratio";

function Home() {
  const {
    chargingSession,
    kwhToday,
    totalUsageToday,
    totalChargers,
    chargerLocations,
    totalRevenue,
    loading,
  } = useAppSelector((state) => state.home);
  const { orgId } = useAppSelector((state) => state.auth);

  // const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch();

  const {
    data: chargingSessionData,
    isLoading: chargingSessionLoading,
    isSuccess: chargingSessionSuccess,
  } = useGetTodayChargingSessionQuery(orgId, { skip: !orgId });
  const {
    data: kwhTodayData,
    isLoading: kwhTodayLoading,
    isSuccess: kwhTodaySuccess,
  } = useGetKwhTodayQuery(orgId, { skip: !orgId });
  const {
    data: totalUsageTodayData,
    isLoading: totalUsageTodayLoading,
    isSuccess: totalUsageTodaySuccess,
  } = useGetTotalUsageTodayQuery(orgId, { skip: !orgId });
  const {
    data: totalChargersData,
    isLoading: totalChargersLoading,
    isSuccess: totalChargersSuccess,
  } = useGetTotalChargersQuery(orgId, { skip: !orgId });
  const {
    data: chargerLocationsData,
    isLoading: chargerLocationsLoading,
    isSuccess: chargerLocationsSuccess,
  } = useGetChargerLocationsQuery(orgId, { skip: !orgId });
  const {
    data: totalRevenueData,
    isLoading: totalRevenueLoading,
    isSuccess: totalRevenueSuccess,
  } = useGetTodayRevenueQuery(orgId, { skip: !orgId });

  useEffect(() => {
    if (
      chargingSessionLoading &&
      kwhTodayLoading &&
      totalUsageTodayLoading &&
      totalChargersLoading &&
      chargerLocationsLoading &&
      totalRevenueLoading
    ) {
      dispatch(startLoading());
    } else if (
      chargingSessionData &&
      kwhTodayData &&
      totalUsageTodayData &&
      totalChargersData &&
      chargerLocationsData &&
      totalRevenueData
    ) {
      dispatch(setChargingSession(chargingSessionData.data));
      dispatch(setKwhToday(kwhTodayData.data));
      dispatch(setTotalUsageToday(totalUsageTodayData.data));
      dispatch(setTotalChargers(totalChargersData.data));
      dispatch(setTotalRevenue(totalRevenueData.data));
      dispatch(setChargerLocations(chargerLocationsData.data));
    }

    if (
      chargingSessionSuccess &&
      kwhTodaySuccess &&
      totalUsageTodaySuccess &&
      chargerLocationsSuccess &&
      totalRevenueSuccess &&
      totalChargersSuccess
    ) {
      dispatch(stopLoading());
    }
  }, [
    dispatch,
    chargingSessionData,
    kwhTodayData,
    totalUsageTodayData,
    totalChargersData,
    chargerLocationsData,
    totalRevenueData,
    chargingSessionLoading,
    kwhTodayLoading,
    totalUsageTodayLoading,
    totalChargersLoading,
    chargerLocationsLoading,
    totalRevenueLoading,
    chargingSessionSuccess,
    kwhTodaySuccess,
    totalUsageTodaySuccess,
    chargerLocationsSuccess,
    totalRevenueSuccess,
    totalChargersSuccess,
  ]);

  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div
            style={{
              backgroundImage: "url(/images/--gf-dashboard-cover.png)",
              backgroundSize: "100% 270px",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="container mb-10 flex flex-col gap-5 bg-cover px-4 pt-4 md:px-9 md:pt-9 2xl:gap-11">
              <Navbar title="Dashboard Overview" />
              {chargingSession &&
                kwhToday &&
                totalUsageToday &&
                totalChargers &&
                totalRevenue && (
                  <div className="-mb-10 flex flex-col gap-5 xl:flex-row">
                    <div className="grid flex-1 grid-cols-2 flex-col justify-between gap-2 md:flex-row md:gap-5">
                      <TotalRevenueCard data={totalRevenue} />

                      <OverviewCard
                        data={[1, 0]}
                        title="Charging Sessions"
                        metric={chargingSession.total_charging_sessions_today}
                        deltaValue={chargingSession.percentageChange}
                        deltaType={
                          isValidDeltaType(chargingSession.deltaType)
                            ? DeltaType[chargingSession.deltaType]
                            : "default"
                        }
                        dayTime="yesterday"
                        className="!w-full"
                      />

                      <OverviewCard
                        data={[1, 0]}
                        title="kWh Charged Today"
                        metric={kwhToday.today_total_energy_consumed_day}
                        deltaValue={kwhToday.percentageChange}
                        deltaType={
                          isValidDeltaType(kwhToday.deltaType)
                            ? DeltaType[kwhToday.deltaType]
                            : "default"
                        }
                        dayTime="yesterday"
                        className="!w-full"
                      />
                      <OverviewCard
                        data={[1, 0]}
                        title="Total Usage Today"
                        metric={`${(totalUsageToday.today_usage_rate * 100).toFixed(2)}%`}
                        deltaValue={totalUsageToday.percentageChange}
                        deltaType={
                          isValidDeltaType(totalUsageToday.deltaType)
                            ? DeltaType[totalUsageToday.deltaType]
                            : "default"
                        }
                        dayTime="yesterday"
                        className="!w-full"
                      />
                    </div>
                    <div className="hidden flex-[0.5] 2xl:block">
                      <TotalChargers data={totalChargers} />
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="container flex flex-col gap-5 px-4 pt-4 md:px-9 md:pt-9 xl:flex-row 2xl:hidden 2xl:gap-11">
            {totalChargers && (
              <div className="w-full flex-[0.5]">
                <TotalChargers data={totalChargers} />
              </div>
            )}
            <div className="h-full flex-[0.5]">
              <Traffic />
            </div>
          </div>
          <div className="container flex flex-col gap-5 px-4 pt-4 md:px-9 md:pt-9 xl:flex-row 2xl:gap-11">
            <div className="flex-1">
              <SalesChart />
            </div>
            <div className="hidden h-full flex-[0.5] 2xl:block">
              <Traffic />
            </div>
          </div>
          <div className="container flex flex-col gap-5 px-4 pt-4 md:flex-row md:px-9 md:pt-9 2xl:gap-11">
            <div className="flex-1">
              <KwhGraph />{" "}
            </div>
            <div className="flex-[0.5]">
              <UtilizationRatio />
            </div>
          </div>
          <div className="container px-4 py-4 pb-8 md:px-9 md:pt-9">
            {chargerLocations && <ChargerLocations data={chargerLocations} />}
          </div>
        </>
      )}
    </div>
  );
}

export default Home;

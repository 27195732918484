import { Button } from "@/components/ui/button";

export function PaymentMethod() {
  return (
    <div className="overflow-hidden rounded-lg border-[.3px] border-gray-500 bg-primary text-white">
      <div className="space-y-2 p-4">
        <h2 className="text-lg font-bold">Payment Method</h2>
        <p className="text-sm">
          You have not yet added any cards. Click the button below to add one.
        </p>
      </div>
      <div className="flex items-center justify-between border-t-[.3px] border-gray-500 bg-primary p-4">
        <span className="text-xs">
          At most, three credit cards, debit cards or prepaid cards can be
          added.
        </span>
        <Button variant="secondary">Add new card method</Button>
      </div>
    </div>
  );
}

import { addMinutes, format, subMonths } from "date-fns";

interface DatesObject {
  [date: string]: number;
}
export function extractDayDates(startDate: Date, endDate: Date): string[] {
  const dayDates: string[] = [];
  const currentDate: Date = new Date(startDate);

  while (currentDate <= endDate) {
    const day: string = currentDate.getDate().toString().padStart(2, "0");
    dayDates.push(day);

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dayDates;
}

function extractMonthDates(selectedMonth: string) {
  if (!selectedMonth) {
    // If no selected month, return an empty array or handle it as needed
    return [];
  }

  const today = new Date();
  const last7MonthsDates: Date[] = [];

  for (let index = 0; index < 7; index++) {
    const currentDate = subMonths(today, index);
    last7MonthsDates.push(currentDate);
  }

  // Generate an array of month-year strings for the last 7 months
  const monthDates: string[] = last7MonthsDates.map((date) =>
    format(date, "MMMM-yyyy"),
  );

  return monthDates;
}

export default extractMonthDates;

export function formatDate(isoDate: string) {
  const date = new Date(isoDate);

  // Define the options for formatting
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
}

export function formatDateShort(isoDate: string) {
  const date = new Date(isoDate);

  // Define the options for formatting
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
}

interface TimeCategory {
  label: string;
  value: Date;
}

export const generateTimeCategories = (
  startTime: string,
  endTime: string,
  intervalMinutes: number,
): TimeCategory[] => {
  const startDateTime = new Date(startTime);
  const endDateTime = new Date(endTime);
  const categories: TimeCategory[] = [];

  let currentTime = startDateTime;

  while (currentTime <= endDateTime) {
    categories.push({
      label: format(currentTime, "HH:mm"),
      value: currentTime,
    });

    currentTime = addMinutes(currentTime, intervalMinutes);
  }

  return categories;
};
export function formatDateToYYYYMMDD(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  const [month, day, year] = formattedDate.split("/");
  return `${year}-${month}-${day}`;
}

export function sortDatesByEarliest(datesObject: DatesObject): number[] {
  const sortedDates = Object.entries(datesObject)
    .map(([date, value]) => ({ date, value }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map((entry) => entry.value);

  return sortedDates;
}

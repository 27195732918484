import { api } from "@/redux/api";

export const tariffApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTafiffPlans: builder.query({
      query: ({ orgId, page = 1 }) =>
        `core_backend/tarrif-plans/?organization=${orgId}&page=${page}`,
      providesTags: ["TariffPlans"],
    }),
    editTariffPlans: builder.mutation({
      query: (values) => ({
        url: `core_backend/tarrif-plans/${values.id}/`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["TariffPlans"],
    }),
    addTariffPlans: builder.mutation({
      query: (values) => ({
        url: `core_backend/tarrif-plans/`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["TariffPlans"],
    }),
    deleteTariffPlans: builder.mutation({
      query: (id) => ({
        url: `core_backend/tarrif-plans/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TariffPlans"],
    }),
  }),
});

export const {
  useGetTafiffPlansQuery,
  useAddTariffPlansMutation,
  useDeleteTariffPlansMutation,
  useEditTariffPlansMutation,
} = tariffApi;

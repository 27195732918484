import { Navbar } from "@/components/navbar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CreateOrganisation } from "./components/create-organisation-modal/create-organisation";
import { organizationSettings, personalSettings } from "./data/settings";

// Define a type for the settings

const Settings = () => {
  const { tab: activeTabFromUrl } = useParams<{ tab?: string }>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [organisation, setOrganisation] = useState<boolean>(false);

  useEffect(() => {
    if (activeTabFromUrl) {
      const tabIndex = personalSettings.findIndex(
        (setting) =>
          setting.name.toLowerCase() === activeTabFromUrl.toLowerCase(),
      );
      if (tabIndex !== -1) {
        setActiveTab(tabIndex);
      }
    }
  }, [activeTabFromUrl]);

  return (
    <div
      style={{
        backgroundImage: "url(/images/--gf-dashboard-cover.png)",
        backgroundSize: "100% 270px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container mb-10 flex flex-col gap-5 bg-cover px-4 pt-4 md:px-9 md:pt-9 2xl:gap-11">
        <Navbar title="Settings" />
        <div className="container h-full">
          <div className="my-4 flex h-full w-full space-x-4 py-4">
            <div className="flex h-[619px] w-full flex-[.3] flex-col rounded-2xl border-[.1px] border-gray-600 bg-background py-4">
              <div className="mb-2 ml-4 text-xs uppercase text-white">
                Personal Settings
              </div>
              {personalSettings.map(
                ({ name, description, icon: Icon }, index) => (
                  <div
                    key={index}
                    className={`relative bg-opacity-25 py-4 duration-150 active:bg-orange-900/75 ${
                      activeTab === index
                        ? "bg-orange-600/25"
                        : "hover:bg-orange-400/25"
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    <div className="mx-4 flex max-w-[340px] cursor-pointer items-start space-x-3 py-2 duration-200">
                      <span className="py-1">
                        <Icon />
                      </span>
                      <div className="flex flex-col gap-1">
                        <h3 className="text-sm font-medium text-white">
                          {name}
                        </h3>
                        <p className="text-[10px] font-normal">{description}</p>
                      </div>
                      {activeTab === index && (
                        <span className="absolute right-0 top-1/2 -translate-y-1/2 transform rounded-l-xl bg-red-600 px-[1.5px] py-4" />
                      )}
                    </div>
                  </div>
                ),
              )}
              <div className="mb-2 ml-4 mt-4 text-xs uppercase text-white">
                Organization Settings
              </div>

              {organizationSettings.map(
                ({ name, description, icon: Icon }, index) => (
                  <div
                    key={index + personalSettings.length}
                    className={`relative bg-opacity-25 py-4 duration-150 active:bg-orange-900/75 ${
                      activeTab === index + personalSettings.length
                        ? "bg-orange-600/25"
                        : "hover:bg-orange-400/25"
                    }`}
                    onClick={() =>
                      setActiveTab(index + personalSettings.length)
                    }
                  >
                    <div className="mx-4 flex max-w-[340px] cursor-pointer items-start space-x-3 py-2 duration-200">
                      <span className="py-1">
                        <Icon />
                      </span>
                      <div className="flex flex-col gap-1">
                        <h3 className="text-sm font-medium text-white">
                          {name}
                        </h3>
                        <p className="text-[10px] font-normal">{description}</p>
                      </div>
                      {activeTab === index + personalSettings.length && (
                        <span className="absolute right-0 top-1/2 -translate-y-1/2 transform rounded-l-xl bg-red-600 px-[1.5px] py-4" />
                      )}
                    </div>
                  </div>
                ),
              )}

              <CreateOrganisation />
            </div>
            <div className="h-[619px] w-full flex-1 overflow-scroll rounded-2xl border-[.1px] border-gray-600 bg-background p-4">
              {activeTab < personalSettings.length
                ? React.createElement(personalSettings[activeTab].component)
                : React.createElement(
                    organizationSettings[activeTab - personalSettings.length]
                      .component,
                  )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

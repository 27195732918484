import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import type { ColumnDef } from "@tanstack/react-table";
import { Actions } from "./actions";
import type { CardHoldersType } from "./schema";

export const columns: ColumnDef<CardHoldersType>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID," />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap">{row.getValue("id")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap">{row.getValue("name")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "designation",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Designations" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap">
        {row.getValue("designation")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "totalElectricityUsage",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Electricity Usage" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">
        {row.getValue("totalElectricityUsage")}
        <span className="text-muted">kWh</span>
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "totalChargingSessions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Charging Sessions" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("totalChargingSessions")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "totalRFIDCards",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total RFID Card" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("totalRFIDCards")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "expirationDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Role Expiration Date" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("expirationDate")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "actions",
    cell: ({ row }) => <Actions row={row} />,
  },
];

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { X } from "lucide-react";
import { useState } from "react";

import type { GroupType } from "../../types";
import { DetailsCard } from "./details-card";

type Properties = {
  children?: React.ReactNode;
  data: GroupType | undefined;
  loading: boolean;
  setSelectedGroupId: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
};

export const GroupDetails = ({
  data,
  children,
  loading,
  setSelectedGroupId,
}: Properties) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <>
      <Dialog modal open={isModalOpen}>
        <DialogContent className="max-h-xl max-w-2xl overflow-scroll border-0 bg-background p-4 text-white shadow-2xl">
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <h2>Group Details {data && "for " + data.name}</h2>
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                  setSelectedGroupId(null);
                }}
                variant="secondary"
                type="button"
              >
                <X />
              </Button>
            </DialogTitle>

            {loading ? (
              <div>Loading</div>
            ) : (
              <div className="flex gap-4">
                {data && <DetailsCard groupData={data} />}
                {/* <RecentFeedsContainer data={data.recentActivity} /> */}

                <div>
                  {/* <GroupChargerTable chargers={data.chargers} /> */}
                </div>
              </div>
            )}
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

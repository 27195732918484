import { AddressAutoComplete } from "@/components/address-auto-complete";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Textarea } from "@/components/ui/textarea";
import { useGlobal } from "@/lib/hooks/use-global";
import { useAddChargeGroupMutation } from "@/redux/features/charger-network/charger-network.api";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { Cross, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

const FormSchema = z.object({
  name: z
    .string({ required_error: "Group name cannot be empty" })
    .min(3, { message: "Group name is too short" })
    .max(50, { message: "Group name is too long" }),
  description: z
    .string()
    .min(3, { message: "Description is too short" })
    .max(50, { message: "Description is too long" })
    .optional(),
  location_description: z
    .string()
    .min(3, { message: "Location Description is too short" })
    .max(50, { message: "Location Description is too long" })
    .optional(),
  latitude: z.number(),
  longitude: z.number(),
  address: z
    .string()
    .max(250, { message: "Address length is too long." })
    .min(2, { message: "Please enter address." }),
});

export function ChargerTableViewOptions() {
  const { orgId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [addchargeGroupMutation, { isLoading }] = useAddChargeGroupMutation();

  const {
    handleGetCountries,
    setSelectedCountry,
    selectedCountry,
    countries: { data: countriesData, loading: countriesLoading },

    handleGetStates,
    states: { data: statesData, loading: statesLoading },
  } = useGlobal();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const handleSubmit = async (values: z.infer<typeof FormSchema>) => {
    const result: any = { ...values, organization: orgId };

    const response = await addchargeGroupMutation(result)
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(error.data.error_message);
      });
  };

  useEffect(() => {
    handleGetCountries();
  }, []);

  useEffect(() => {
    handleGetStates();
  }, [selectedCountry]);

  return (
    <Sheet modal open={isOpen}>
      <SheetTrigger asChild>
        <Button
          variant="outline"
          onClick={() => {
            form.reset();
            setIsOpen(!isOpen);
          }}
        >
          <Cross className="mr-2 h-4 w-4" />
          Add Charger Groups
        </Button>
      </SheetTrigger>
      <SheetContent className="max-h[50vh] overflow-scroll">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="flex w-full items-start justify-between">
              <SheetHeader>
                <SheetTitle> Add Charger groups</SheetTitle>
                <SheetDescription>
                  Add charger groups for your charge points here.
                </SheetDescription>
              </SheetHeader>
              <Button
                type="button"
                onClick={() => {
                  form.reset();
                  setIsOpen(false);
                }}
                variant={"secondary"}
                className="h-auto p-2"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
            <div className="grid gap-4 py-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <>
                    <FormItem className="grid grid-cols-4 items-center gap-4">
                      <FormLabel className="whitespace-nowrap text-right">
                        Name
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Name"
                          className="col-span-3"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>{" "}
                    <FormMessage className="text-right text-[10px]" />
                  </>
                )}
              />

              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-4 items-center gap-4">
                    <FormLabel className="whitespace-nowrap text-right">
                      Address
                    </FormLabel>
                    <FormControl>
                      <div className="col-span-3">
                        <AddressAutoComplete
                          onSelectAddress={(address, latitude, longitude) => {
                            form.setValue("address", address);
                            form.setValue("latitude", latitude as number);
                            form.setValue("longitude", longitude as number);
                          }}
                          defaultValue=""
                        />
                      </div>
                    </FormControl>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <>
                    <FormItem className="grid grid-cols-4 items-start gap-4">
                      <FormLabel className="whitespace-nowrap pt-2 text-right">
                        Description
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Description"
                          className="col-span-3"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>{" "}
                    <FormMessage className="text-right text-[10px]" />
                  </>
                )}
              />

              <FormField
                control={form.control}
                name="location_description"
                render={({ field }) => (
                  <>
                    <FormItem className="grid grid-cols-4 items-start gap-4">
                      <FormLabel className="whitespace-nowrap pt-2 text-right">
                        Location
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Add a description to desribe the location."
                          className="col-span-3"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>{" "}
                    <FormMessage className="text-right text-[10px]" />
                  </>
                )}
              />
            </div>
            <SheetFooter>
              <Button disabled={isLoading} variant="secondary" type="submit">
                Create
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}

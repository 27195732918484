import { Actions } from "./actions";
import type { AccessProfileType } from "./schema";
import { Checkbox } from "@/components/ui/checkbox";
import type { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";

export const columns: ColumnDef<AccessProfileType>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "profileName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Profile Name" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("profileName")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "accessLevel",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Access Level" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("accessLevel")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "totalActiveRFIDCards",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Active RFID Cards" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("totalActiveRFIDCards")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "profileExpirationDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Profile Expiration Date" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("profileExpirationDate")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "actions",
    cell: ({ row }) => <Actions row={row} />,
  },
];

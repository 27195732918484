import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { X } from "lucide-react";
import type React from "react";
import { useState } from "react";
import { DetailsCard } from "./details-card";
import { RFIDCardDataTable } from "./rfid-card-table/data-table";
import { columns as RFIDColumn } from "./rfid-card-table/column";
import { columns as sessionHistoryColumn } from "./session-history-table/column";
import { CreateRFIDCard } from "./rfid-card-table/create-rfid-card";
import { SessionHistoryDataTable } from "./session-history-table/session-history-data-table";
import type { CardHoldersType } from "../schema";
import { generateRFIDCards } from "./rfid-card-table/data";
import { generateSessionHistory } from "./session-history-table/data";

type Props = { data: CardHoldersType; children: React.ReactNode };

export const Details = ({ data, children }: Props) => {
  const rfidCards = generateRFIDCards(30);
  const sessionHistory = generateSessionHistory(30);

  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Dialog
        modal
        open={isModalOpen}
        onOpenChange={() => setIsModalOpen(true)}
      >
        <DialogTrigger
          asChild
          onClick={() => setIsModalOpen(true)}
          className="cursor-pointer"
        >
          {children}
        </DialogTrigger>
        <DialogContent className="max-h-xl scrollbar-thin scrollbar-track-gray-600 max-h-[90%] max-w-[90%] border-gray-700 border overflow-scroll bg-background p-4 text-white shadow-2xl">
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <h2>Driver Details</h2>
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="secondary"
                type="button"
              >
                <X size={16} color="black" />
              </Button>
            </DialogTitle>
          </DialogHeader>

          <div className="flex gap-4">
            <div className="space-y-4">
              <DetailsCard data={data} />
              <div className="container bg-black/50 rounded-lg border-[.5px] border-slate-400  p-2 text-white">
                <div className="flex items-center justify-between">
                  <h2 className="text-2xl font-bold mb-4">Session History</h2>

                  {/* <RolesTableViewOptions /> */}
                </div>

                <SessionHistoryDataTable
                  data={sessionHistory}
                  columns={sessionHistoryColumn}
                />
              </div>
            </div>
            {/* <ElectricityUsageCard /> */}

            <div className="container bg-black/50 rounded-lg  border-slate-500 border-[.5px] space-y-3 p-2 text-white">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold mb-4">Card Details.</h2>
                <CreateRFIDCard />
                {/* <RolesTableViewOptions /> */}
              </div>

              <RFIDCardDataTable data={rfidCards} columns={RFIDColumn} />
            </div>
          </div>
          {/* <RecentFeedsContainer data={data.recentActivities} /> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

import BillingAddress from "./billing-address";
import { InvoiceEmail } from "./invoice-email";
import { PaymentMethod } from "./payment-method";
import { Pricing } from "./pricing";

export function Billing() {
  return (
    <div className="space-y-4">
      <PaymentMethod />
      <InvoiceEmail />
      <BillingAddress />
      <Pricing />
    </div>
  );
}

import { Button, buttonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils/cn";
import { useGetSingleChargePointQRCodeMutation } from "@/redux/features/charger-network/charger-network.api";
import { zodResolver } from "@hookform/resolvers/zod";
import { QrCode } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

type Properties = {
  chargePointId?: string;
};

const FormSchema = z.object({
  type: z.enum(["png", "pdf", "all"], {
    required_error: "You need to select a download format.",
  }),
});

const GenerateQRCode = ({ chargePointId }: Properties) => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const [getQRCode, { isLoading }] = useGetSingleChargePointQRCodeMutation();

  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    (async () => {
      getQRCode(chargePointId)
        .unwrap()
        .then((response) => {
          setImgUrl(response);
        })
        .catch((error) => {
          console.error(error);
        });
    })();
  }, []);

  const downloadQRCode = () => {
    const link = document.createElement("a");
    link.href = imgUrl;
    link.setAttribute(
      "download",
      `GRIDFLOW-${chargePointId?.toUpperCase()}-CHARGEPOINT.png`,
    );
    document.body.append(link);
    link.click();
    link.remove();
  };
  return (
    <Dialog>
      <DialogTrigger
        className={cn(
          buttonVariants({ variant: "secondary" }),
          "flex items-center gap-2 bg-secondary/70",
        )}
      >
        <QrCode size={18} />
        Generate QR Code
      </DialogTrigger>
      <DialogContent className="max-w-xs border-[#666] p-4">
        <div className="min-h-60 rounded-lg bg-[#333] shadow-inner shadow-[#666]">
          <img src={imgUrl} />
        </div>
        <Button
          onClick={downloadQRCode}
          type="submit"
          variant="secondary"
          size="sm"
        >
          Download
        </Button>
        <DialogClose>Close</DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateQRCode;

import { formatDate } from "@/lib/helpers/dates";
import { faker } from "@faker-js/faker";
import type { SessionHistoryType } from "./schema";

export const generateSessionHistory = (count: number): SessionHistoryType[] => {
  const sessions = [];

  for (let index = 0; index < count; index++) {
    const session = {
      sessionId: `SSHS-${faker.number.int({ min: 10, max: 100 })}`,
      electricityUsage: faker.number.int({ min: 50, max: 100 }),
      chargerId: `CHRG-${faker.number.int({ min: 10, max: 100 })}`,
      location: faker.location.streetAddress({ useFullAddress: true }),
      expirationDate: formatDate(faker.date.recent().toString()),
    };
    sessions.push(session);
  }
  return sessions;
};

"use client";

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Slider } from "@/components/ui/slider";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Switch } from "@/components/ui/switch";
import { useToast } from "@/components/ui/use-toast";
import {
  Clock,
  Calendar as CalendarIcon,
  BarChart2,
  Zap,
  Plus,
} from "lucide-react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils/cn";
import { format } from "date-fns";

type LimitPeriod = "daily" | "weekly" | "monthly" | "allTime";
type LimitType = "time" | "energy";

interface Limit {
  value: number;
  enabled: boolean;
}

const limitPeriods: {
  id: LimitPeriod;
  label: string;
  icon: React.ReactNode;
  maxTime: number;
  maxEnergy: number;
}[] = [
  {
    id: "daily",
    label: "Daily",
    icon: <Clock className="w-4 h-4" />,
    maxTime: 1440,
    maxEnergy: 100,
  },
  {
    id: "weekly",
    label: "Weekly",
    icon: <CalendarIcon className="w-4 h-4" />,
    maxTime: 10080,
    maxEnergy: 500,
  },
  {
    id: "monthly",
    label: "Monthly",
    icon: <BarChart2 className="w-4 h-4" />,
    maxTime: 44640,
    maxEnergy: 2000,
  },
  {
    id: "allTime",
    label: "All-Time",
    icon: <BarChart2 className="w-4 h-4" />,
    maxTime: 525600,
    maxEnergy: 10000,
  },
];

const formSchema = z.object({
  name: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),
  group: z.string().min(2, {
    message: "Group must be at least 2 characters.",
  }),
  accessLevel: z.enum(["basic", "standard", "premium"], {
    required_error: "Please select an access level.",
  }),
  expirationDate: z
    .date({
      required_error: "Expiration date is required.",
    })
    .refine((date) => date > new Date(), {
      message: "Expiration date must be in the future.",
    }),
  timeLimits: z.record(
    z.object({
      value: z.number(),
      enabled: z.boolean(),
    })
  ),
  energyLimits: z.record(
    z.object({
      value: z.number(),
      enabled: z.boolean(),
    })
  ),
});

export const CreateAccessProfile = () => {
  const [activeTab, setActiveTab] = useState<LimitType>("time");
  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      group: "",
      accessLevel: undefined,
      expirationDate: undefined,
      timeLimits: {
        daily: { value: 120, enabled: false },
        weekly: { value: 600, enabled: false },
        monthly: { value: 2400, enabled: false },
        allTime: { value: 10000, enabled: false },
      },
      energyLimits: {
        daily: { value: 20, enabled: false },
        weekly: { value: 100, enabled: false },
        monthly: { value: 400, enabled: false },
        allTime: { value: 2000, enabled: false },
      },
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values);
    toast({
      title: "Success",
      description: "Access profile has been created",
    });
  }

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  const formatEnergy = (kWh: number) => {
    return `${kWh} kWh`;
  };

  const renderLimitControls = (type: LimitType) => {
    const limits = type === "time" ? "timeLimits" : "energyLimits";
    const formatValue = type === "time" ? formatTime : formatEnergy;
    const maxValue = type === "time" ? "maxTime" : "maxEnergy";

    return limitPeriods.map((period) => (
      <div key={period.id} className="mt-6 space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-300">
            {period.label} {type === "time" ? "Time" : "Energy"} Limit:
          </span>
          <FormField
            control={form.control}
            name={`${limits}.${period.id}.enabled`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        {form.watch(`${limits}.${period.id}.enabled`) && (
          <>
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold ">
                {formatValue(form.watch(`${limits}.${period.id}.value`))}
              </span>
            </div>
            <FormField
              control={form.control}
              name={`${limits}.${period.id}.value`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Slider
                      min={0}
                      max={period[maxValue]}
                      step={type === "time" ? 15 : 1}
                      value={[field.value]}
                      onValueChange={(value) => field.onChange(value[0])}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="flex justify-between text-sm text-gray-400">
              <span>{formatValue(0)}</span>
              <span>{formatValue(period[maxValue])}</span>
            </div>
          </>
        )}
      </div>
    ));
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
        >
          <Plus className="mr-2 h-4 w-4" />
          Add Access Profile
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-lg p-2 bg-gray-900 bg-background text-gray-100 border-gray-700">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold ">
            Create Access Profile
          </DialogTitle>
          <DialogDescription className="text-gray-400">
            Set optional maximum charging session durations and energy
            consumption for RFID cards
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="grid grid-cols-2 gap-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium text-gray-300">
                      Name
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="bg-gray-800 border-gray-700 text-gray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="group"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium text-gray-300">
                      Group
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="bg-gray-800 border-gray-700 text-gray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="accessLevel"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium text-gray-300">
                      Access Profile Level
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="bg-gray-800 border-gray-700 text-gray-100">
                          <SelectValue placeholder="Select level" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="bg-gray-800 border-gray-700 text-gray-100">
                        <SelectItem value="basic">Basic</SelectItem>
                        <SelectItem value="standard">Standard</SelectItem>
                        <SelectItem value="premium">Premium</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="expirationDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel className="text-sm font-medium text-gray-300">
                      Expiration Date
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "w-full pl-3 text-left font-normal bg-gray-800 border-gray-700 text-gray-100",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-auto p-0 bg-gray-800 border-gray-700"
                        align="start"
                      >
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date < new Date() || date > new Date("2100-01-01")
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Tabs
              value={activeTab}
              onValueChange={(value) => setActiveTab(value as LimitType)}
              className="w-full"
            >
              <TabsList className="flex bg-gray-800">
                <TabsTrigger
                  value="time"
                  className="h-full w-full flex-1 font-bold flex items-center space-x-2 data-[state=active]:bg-gray-700 data-[state=active]:text-white"
                >
                  <Clock className="w-4 h-4" />
                  <span>Time Limits</span>
                </TabsTrigger>
                <TabsTrigger
                  value="energy"
                  className="h-full w-full flex-1 font-bold flex items-center space-x-2 data-[state=active]:bg-gray-700 data-[state=active]:text-white"
                >
                  <Zap className="w-4 h-4" />
                  <span>Energy Limits</span>
                </TabsTrigger>
              </TabsList>

              <TabsContent value="time">
                {renderLimitControls("time")}
              </TabsContent>
              <TabsContent value="energy">
                {renderLimitControls("energy")}
              </TabsContent>
            </Tabs>
            <div className="mt-8 p-4 bg-gray-800 rounded-lg">
              <h3 className="text-lg font-semibold mb-2 text-gray-200">
                Summary of Enabled Limits
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {limitPeriods.map((period) => (
                  <div key={period.id} className="space-y-1">
                    <div className="flex items-center space-x-2">
                      {period.icon}
                      <span className="text-sm font-medium text-gray-300">
                        {period.label}:
                      </span>
                    </div>
                    <div className="pl-6 text-sm">
                      {form.watch(`timeLimits.${period.id}.enabled`) && (
                        <div className="text-gray-300">
                          Time:{" "}
                          {formatTime(
                            form.watch(`timeLimits.${period.id}.value`)
                          )}
                        </div>
                      )}
                      {form.watch(`energyLimits.${period.id}.enabled`) && (
                        <div className="text-gray-300">
                          Energy:{" "}
                          {formatEnergy(
                            form.watch(`energyLimits.${period.id}.value`)
                          )}
                        </div>
                      )}
                      {!form.watch(`timeLimits.${period.id}.enabled`) &&
                        !form.watch(`energyLimits.${period.id}.enabled`) && (
                          <div className="text-gray-500">No limits set</div>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                className="w-full bg-gray-800 hover:bg-gray-700 text-gray-200"
                onClick={() => {
                  // Close the dialog
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="w-full bg-primary hover:bg-primary-dark text-white"
              >
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

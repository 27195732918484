import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

export function InvoiceEmail() {
  return (
    <div className="overflow-hidden rounded-lg border-[.3px] border-gray-500 bg-primary text-white">
      <div className="space-y-2 p-4">
        <h2 className="text-lg font-bold">Invoice Email</h2>
        <p className="text-sm">
          By default, all your invoices will be sent to the email address of the
          creator of your team. If you want to use a custom email address
          specifically for receiving invoices, enter it here.
        </p>
        <div>
          <Input placeholder="invoices@grdflo.com" className="md:w-[50%]" />
        </div>
      </div>
      <div className="flex items-center justify-between border-t-[.3px] border-gray-500 bg-primary p-4">
        <span className="text-xs">Please use 254 characters at maximum.</span>
        <Button variant="secondary">Add Email address</Button>
      </div>
    </div>
  );
}

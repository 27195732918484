import { appRoutes } from "@/common/routes/route";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Route, Routes } from "react-router-dom";

import { MobileWarning, NotFound } from "../pages";

function App() {
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 800) setShowMobileWarning(true);
  }, []);

  if (showMobileWarning) return <MobileWarning />;

  return (
    <DndProvider backend={HTML5Backend}>
      <Routes>
        {appRoutes.map(({ path, element }, index) => (
          <Route key={index} path={path} element={element} />
        ))}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </DndProvider>
  );
}

export default App;

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { TableRow } from "@/components/ui/table";
import type React from "react";
import { useState } from "react";

type Properties = { children: React.ReactNode };

export const Details = ({ children }: Properties) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <TableRow onClick={() => setIsModalOpen(true)} className="cursor-pointer">
        {children}
      </TableRow>

      <Dialog
        modal
        open={isModalOpen}
        onOpenChange={() => setIsModalOpen(true)}
      >
        <DialogContent className="max-h-xl scrollbar-thin scrollbar-track-gray-600 max-h-[70%] max-w-[70%] overflow-y-scroll border-0 bg-primary p-4 text-white shadow-2xl">
          <DialogHeader>
            <DialogTitle>Are you sure absolutely sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete your
              account and remove your data from our servers.
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="w-full">
            <div className="flex w-full items-center justify-center">
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="outline"
                type="button"
              >
                Cancel
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

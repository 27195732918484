import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import type { ChargePointType } from "@/types/charger-network";
import { Copy, X } from "lucide-react";
import { MdConnectWithoutContact } from "react-icons/md";
import { toast } from "sonner";

import { formatDate } from "@/lib/helpers/dates";
import { ConnectorTable } from "./connector-table/connector-table";
import GenerateQRCode from "./generate-qr-code";

type Properties = {
  data?: ChargePointType;
  setModalOpen: any;
};

export const ChargerDetails = ({ data, setModalOpen }: Properties) => {
  return (
    <Dialog modal open>
      <DialogContent className="scrollbar-thin scrollbar-track-gray-600 max-h-[80%] max-w-3xl space-y-6 overflow-scroll border-0 bg-background p-4 text-white shadow-2xl">
        <DialogTitle className="flex items-center justify-between">
          <h2 className="my-5">
            Chargepoint Details for {data?.charge_point_id}
          </h2>
          <div className="flex items-center justify-between gap-2">
            <GenerateQRCode chargePointId={data?.charge_point_id} />
            <Button
              onClick={() => setModalOpen(null)}
              variant="secondary"
              type="button"
              className="h-auto p-2"
            >
              <X />
            </Button>
          </div>
        </DialogTitle>

        <div className="flex flex-1 items-center justify-between rounded-lg bg-gray-800 px-4 py-2 text-xs text-green-400">
          <span>
            wss://core.grdflo.com/ocpp/1.6/central_system/
            {data?.charge_point_id}
          </span>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                "wss://core.grdflo.com/ocpp/1.6/central_system/" +
                  data?.charge_point_id,
              );
              toast.success(
                `Copied wss://core.grdflo.com/ocpp/1.6/central_system/${data?.charge_point_id}`,
              );
            }}
            className="h-auto p-2"
          >
            <Copy size={18} />
          </Button>
        </div>

        <div className="flex gap-2">
          <div className="w-max space-y-4 rounded-lg border px-4 pb-4 shadow-sm shadow-slate-300">
            <div className="flex items-center gap-2 rounded-b-lg bg-amber-600 p-2">
              <div>
                <MdConnectWithoutContact size={35} />
              </div>
              <div className="">
                <p className="text-xl font-bold uppercase">TESt Charger</p>
                <p className="upper text-sm">CS-BASIC-00001</p>
              </div>
            </div>
            <div className="flex gap-4">
              <ul className="list-disc space-y-2 pl-4">
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Charger Make
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.charger_make}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Charger Model
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.charger_model}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Charger Status
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.charger_status}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Charger Type
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.charger_type}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Installation Date
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.installation_date}
                    </span>
                  </div>
                </li>
              </ul>
              <ul className="list-disc space-y-2 pl-4">
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Max Amperage
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.max_amperage}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Max Charging Power
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.max_amperage}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Max Voltage
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.max_voltage}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Serial Number
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.serial_number}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col">
                    <span className="whitespace-nowrap text-xs font-semibold">
                      Warranty Expiry Date
                    </span>
                    <span className="text-[10px] text-[#a3a3a3]">
                      {data?.warranty_expiry_date
                        ? formatDate(data?.warranty_expiry_date)
                        : "No warantty expiry date"}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <ConnectorTable chargePointId={data?.id} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

import request from "@/lib/utils/axios";
import { AxiosError } from "axios";

const orgId = localStorage.getItem("org_id");
export const getTransactionRecords = async (value: { org_id: string }) => {
  try {
    if (orgId) {
      const res = await request.get(
        `/core_backend/revenue/transaction-records/?organization=${orgId}`,
      );
      return res.data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getTransactionRecordsById = async (value: {
  transaction_id: string;
}) => {
  try {
    const res = await request.get(
      `/core_backend/revenue/transaction-records/${value.transaction_id}`,
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getTodayRevenue = async (value: { org_id: string }) => {
  try {
    if (orgId) {
      const res = await request.get(
        `/core_backend/today-total-revenue/?organization=${orgId}`,
      );
      return res.data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getTodayChargingSession = async (value: { org_id: string }) => {
  try {
    if (orgId) {
      const res = await request.get(
        `/core_backend/today-charging-session-analytics/?organization=${orgId}`,
      );
      return res.data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getTodayTotalUsage = async (value: { org_id: string }) => {
  try {
    if (orgId) {
      const res = await request.get(`/core_backend/total-usage-today/${orgId}`);
      return res.data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getTimeframeUsage = async (value: { timeframe: number }) => {
  try {
    if (orgId) {
      const res = await request.get(
        `/core_backend/timeframe-utilization/${orgId}?timeframe=${value.timeframe}`,
      );
      return res.data;
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getUsageToday = async (value: { org_id: string }) => {
  try {
    if (orgId) {
      const res = await request.get(
        `/core_backend/today-energy-consumed-analytics/?organization=${orgId}`,
      );
      return res.data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getTotalChargerStatus = async (value: { org_id: string }) => {
  try {
    if (orgId) {
      const res = await request.get(`/core_backend/charger-status/${orgId}`);
      return res.data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getChargerLocations = async (value: { org_id: string }) => {
  try {
    if (orgId) {
      const res = await request.get(
        `/core_backend/charger-map-visual/${orgId}`,
      );
      return res.data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getChargerGroupContributionsDaily = async (value: {
  org_id: string;
}) => {
  try {
    if (orgId) {
      const res = await request.get(
        `/core_backend/org-charger-group-daily-revenue-contribution/?organization=${orgId}`,
      );
      return res.data;
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const exportTransactionRecordsCSV = async (value: {
  charger_groups_id: string;
  fromDate: string;
  toDate: string;
}) => {
  try {
    const res = await request.get(
      `core_backend/revenue/transaction-records/export-csv/?start_date=${value.fromDate}&end_date=${value.toDate}&charger_group_id=${value.charger_groups_id}`,
      {
        responseType: "blob", // Important
      },
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const exportTransactionRecordsPDF = async (value: {
  charger_groups_id: string;
  fromDate: string;
  toDate: string;
}) => {
  try {
    const res = await request.get(
      `core_backend/revenue/transaction-records/export-pdf/?start_date=${value.fromDate}&end_date=${value.toDate}&charger_group_id=${value.charger_groups_id}`,
      {
        responseType: "blob", // Important
      },
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CardHoldersDataTable } from "./card-holders-table/data-table";
import { CreateCardHolders } from "./card-holders-table/create-card-holders";
import { generateCardHolders } from "./card-holders-table/data";
import { columns as createAccessProfileColumn } from "./card-holders-table/column";
import { CreateAccessProfile } from "./access-profile-table/create-access-profile";
import { generateAccessProfiles } from "./access-profile-table/data";
import { AccessProfileDataTable } from "./access-profile-table/data-table";
import { columns as accessProfileDataTableColumn } from "./access-profile-table/column";

export const CardHoldersAndAccessProfileTab = () => {
  const cardHolders = generateCardHolders(30);
  const accessProfiles = generateAccessProfiles(30);

  return (
    <div className="relative my-8 rounded-lg bg-gradient-linear py-4 text-white backdrop-blur-xl">
      <Tabs
        defaultValue="drivers"
        className="container space-y-6 bg-transparent"
      >
        <TabsList className="flex h-14 bg-slate-700">
          <TabsTrigger
            value="drivers"
            className="h-full w-full flex-1 text-xl font-bold"
          >
            Card Holders
          </TabsTrigger>
          <TabsTrigger
            value="roles"
            className="h-full w-full flex-1 text-xl font-bold"
          >
            Access Profiles
          </TabsTrigger>
        </TabsList>

        <TabsContent value="drivers">
          <div className="container rounded-lg border border-slate-400 p-6 text-white">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-bold mb-4">Manage Card holders.</h2>

              <CreateCardHolders />
            </div>

            <CardHoldersDataTable
              data={cardHolders}
              columns={createAccessProfileColumn}
            />
          </div>
        </TabsContent>
        <TabsContent value="roles">
          <div className="container rounded-lg border border-slate-400 p-6 text-white">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-bold mb-4">
                Manage your Access profiles.
              </h2>

              <CreateAccessProfile />
            </div>

            <AccessProfileDataTable
              data={accessProfiles}
              columns={accessProfileDataTableColumn}
            />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

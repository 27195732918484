import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { ChargerTable } from "./charger-table/charger-tables";
import { GroupsTable } from "./group-table/group-tables";

type Properties = {};

export const ChargerTabs = (properties: Properties) => {
  return (
    <div className="relative my-8 rounded-lg bg-gradient-linear py-4 text-white backdrop-blur-xl">
      <Tabs
        defaultValue="chargers"
        className="container space-y-6 bg-transparent"
      >
        <TabsList className="flex h-14 bg-slate-700">
          <TabsTrigger
            value="chargers"
            className="h-full w-full flex-1 text-xl font-bold"
          >
            Chargers
          </TabsTrigger>
          <TabsTrigger
            value="groups"
            className="h-full w-full flex-1 text-xl font-bold"
          >
            Groups
          </TabsTrigger>
        </TabsList>

        <TabsContent value="chargers">
          <ChargerTable />
        </TabsContent>
        <TabsContent value="groups">
          <GroupsTable />
        </TabsContent>
      </Tabs>
    </div>
  );
};

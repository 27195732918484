import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";

type Properties = {};

export const Pricing = (properties: Properties) => {
  const pricing = [
    {
      name: "Starter",
      amount: 100,
      features: [
        "Up to 20 Charger Connectors",
        "Plus 2% transaction fee on GridFlow Drive app",
        "Custom charger branding on GridFlow Drive app",
        "Basic report generation",
      ],
      currentPlan: true,
    },
    {
      name: "Growth",
      amount: 80,
      features: [
        "21 - 100 Charger Connectors",
        "Plus 1.5% transaction fee on GridFlow Drive app",
        "Advanced custom charger branding on GridFlow Drive app",
        "Advanced report generation",
        "Priority customer support",
      ],
      currentPlan: false,
    },
    {
      name: "Enterprise",
      amount: 100,
      features: [
        "More than 100 Charger Connectors",
        "Custom Pricing - Contact Us for a Personalised Quote",
        "Custom transaction fee structure on GridFlow Drive app",
        "Premium custom charger branding on GridFlow Drive app",
        "Customizable report generation",
        "Premium priority support",
      ],
      currentPlan: false,
    },
  ];
  return (
    <div className="overflow-hidden rounded-lg border-[.3px] border-gray-500 bg-primary text-white">
      <div className="space-y-2 border-b-[.3px] border-gray-500 bg-primary p-4">
        <h2 className="text-lg font-bold">Core Pricing</h2>
        <p className="text-sm">
          You have not yet added any cards. Click the button below to add one.
        </p>
      </div>
      <div className="container flex gap-4 py-4">
        {pricing.map((price) => (
          <div className="relative flex flex-col justify-between rounded-3xl bg-black/100 p-4">
            {price.currentPlan && (
              <div className="absolute right-4 rounded-lg border border-gray-600 px-2 py-1 text-[8px] text-yellow-600">
                current plan
              </div>
            )}
            <div className="relative flex-1 space-y-4">
              <div className="space-y-2 border-b pb-3">
                <h2>{price.name}</h2>
                <div>
                  <span className="text-4xl font-medium">
                    HK${price.amount}
                  </span>
                  <span>/connector/month</span>
                </div>
              </div>
              {price.features.map((feature) => (
                <div className="flex items-center gap-2">
                  <Check size={16} />
                  <span className="text-xs text-slate-300">{feature}</span>
                </div>
              ))}
            </div>{" "}
            <div className="flex items-center justify-center py-4">
              {price.currentPlan ? (
                <Button variant="secondary">Details</Button>
              ) : (
                <Button variant="secondary">Upgrade</Button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between border-t-[.3px] border-gray-500 bg-primary p-4">
        <span className="text-xs">
          At most, three credit cards, debit cards or prepaid cards can be
          added.
        </span>
        <Button variant="secondary">Add new card method</Button>
      </div>
    </div>
  );
};

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils/cn";
import { useGetChargePointsQuery } from "@/redux/features/charger-network/charger-network.api";
import {
  setChargePoints,
  setShowInstructions,
  startLoading,
} from "@/redux/features/charger-network/charger-network.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import { ChargerDataTable } from "./charger-data-table";
import { columns } from "./column";

export const ChargerTable: React.FC = () => {
  const [page, setPage] = useState(1);
  const { orgId } = useAppSelector((state) => state.auth);
  const [searchPage, setSearchPage] = useState(page);
  const dispatch = useAppDispatch();
  const {
    data: { chargePoints: chargers },
    loading,
  } = useAppSelector((state) => state.chargerNetwork);

  const [totalPages, setTotalPages] = useState(0);

  const { data, isFetching, isError, isLoading } = useGetChargePointsQuery({
    orgId,
    page,
  });

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setChargePoints(data));
      const itemsPerPage = 10;
      const totalPages = Math.ceil(data.data.count / itemsPerPage);
      setTotalPages(totalPages);
    }
  }, [chargers, data, dispatch, isFetching]);

  const goToFirstPage = () => {
    setPage(1);
    setSearchPage(1);
  };

  const goToLastPage = () => {
    setPage(totalPages);
    setSearchPage(totalPages);
  };
  const goToNextPage = () => {
    setPage(page + 1);
    setSearchPage(page + 1);
  };
  const goToPreviousPage = () => {
    setPage(page - 1);
    setSearchPage(page - 1);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPage(Number.parseInt(e.target.value));
  };

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const pageNumber = searchPage;
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setPage(pageNumber);
    }
  };

  return (
    <div className="container rounded-lg border border-slate-400 bg-black p-6 text-white">
      <div className="mb-4 flex justify-between">
        <h2 className="text-2xl font-bold">Your charge points.</h2>
        <Button
          onClick={() => dispatch(setShowInstructions(true))}
          variant="link"
          className="px-0 text-sm text-[#666]"
        >
          How to connect my charge points?
        </Button>
      </div>

      <ChargerDataTable
        loading={loading}
        data={chargers ? chargers.results : []}
        columns={columns}
      />

      {loading ? (
        <div className="my-2 flex justify-end">
          <div className="flex justify-end gap-2">
            <Skeleton className="h-6 w-12 rounded-sm" />
            <Skeleton className="h-6 w-12 rounded-sm" />
            <Skeleton className="h-6 w-12 rounded-sm" />
          </div>
        </div>
      ) : (
        chargers && (
          <Pagination className="justify-end pr-6 pt-4">
            <PaginationContent>
              {chargers.previous && (
                <>
                  <PaginationItem onClick={goToFirstPage}>
                    <PaginationLink
                      href="#"
                      aria-label="Go to last page"
                      size="default"
                      className={cn("gap-1 pr-2.5")}
                    >
                      <ChevronDoubleLeftIcon className="h-4 w-4" />
                      <span>First</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem onClick={goToPreviousPage}>
                    <PaginationPrevious href="#" />
                  </PaginationItem>
                </>
              )}
              <PaginationItem>
                <form
                  onSubmit={handleSearchSubmit}
                  className="flex items-center"
                >
                  <Input
                    type="number"
                    value={searchPage}
                    onChange={handleSearchChange}
                    placeholder="Go to page"
                    className="h-10 w-12"
                  />
                </form>
              </PaginationItem>
              {chargers.next && (
                <>
                  <PaginationItem onClick={goToNextPage}>
                    <PaginationNext href="#" />
                  </PaginationItem>
                  <PaginationItem onClick={goToLastPage}>
                    <PaginationLink
                      href="#"
                      aria-label="Go to last page"
                      size="default"
                      className={cn("gap-1 pr-2.5")}
                    >
                      <span>Last</span>
                      <ChevronDoubleRightIcon className="h-4 w-4" />
                    </PaginationLink>
                  </PaginationItem>
                </>
              )}
            </PaginationContent>
          </Pagination>
        )
      )}
    </div>
  );
};

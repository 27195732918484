import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useGetTransactionRecordsByIdQuery } from "@/redux/features/revenue/revenue.api";
import { X } from "lucide-react";
import { useEffect, useState } from "react";

import { ChargerDetails } from "./charger-details";
import { CostDetails } from "./cost-details";
import { DriverDetails } from "./driver-details";
import { SessionDetails } from "./session-details-card";

interface TransactionsDataType {
  charging_session_detail: {
    charger_id: string;
    connector_id: number;
    duration: string;
    electricity_usage: number;
    initiated_by: string;
    session_id: string;
    transaction_status: string;
    transaction_timeline: string;
  };
  charger_usage_details: {
    charger_group_name: string;
    charger_id: string;
    charger_name: string;
    firmware_version: string;
    protocol: string;
    vendor_model: string;
  };
  cost_detials: {
    cost: number;
    payment_method: string;
    payment_status: string;
    transaction_reference_number: string;
  };
  vehicle_driver_details: {
    contact_information: string;
    driver_id: string;
    driver_name: string;
    vehicle_description: string;
  };
}

const TransactionDetails = ({
  id,
  setTransactionId,
}: {
  id: string;
  setTransactionId: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [details, setDetails] = useState<TransactionsDataType>();

  const { data, isFetching, isError } = useGetTransactionRecordsByIdQuery({
    transaction_id: id,
  });

  useEffect(() => {
    if (data && data.status == "success" && data.data) {
      setDetails(data.data);
    }
  }, [data]);

  return (
    <>
      <Dialog
        modal
        open={isModalOpen}
        onOpenChange={() => setIsModalOpen(true)}
      >
        <DialogContent className="max-h-[70%] max-w-5xl overflow-scroll border-0 bg-primary p-4 text-white shadow-2xl">
          <DialogHeader>
            <DialogTitle className="mb-6 flex items-center justify-between">
              <h2>Transaction Details</h2>

              <div className="flex items-center justify-between gap-4">
                <Button variant="secondary" className="py-1">
                  Print
                </Button>
                <Button
                  onClick={() => {
                    setTransactionId(null);
                    setIsModalOpen(false);
                  }}
                  variant="secondary"
                  type="button"
                >
                  <X size={16} color="black" />
                </Button>
              </div>
            </DialogTitle>
            {details && (
              <DialogDescription className="flex gap-4">
                <div className="grid grid-cols-2 items-center gap-3">
                  <SessionDetails data={details.charging_session_detail} />
                  <ChargerDetails data={details.charger_usage_details} />
                  <CostDetails data={details.cost_detials} />
                  <DriverDetails data={details.vehicle_driver_details} />
                </div>
                {/* <div className="flex-1">
								{data && <EnergyUtilisation data={data.data} />}
							</div> */}
              </DialogDescription>
            )}
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransactionDetails;

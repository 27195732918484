import { Country, State } from "country-state-city";
import { useEffect, useState } from "react";

import type { GroupType } from "../../types";

interface GroupCardProperties {
  groupData: GroupType;
}

export const DetailsCard: React.FC<GroupCardProperties> = ({ groupData }) => {
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    (async () => {
      const countryResponse = await Country.getCountryByCode(groupData.country);
      const stateResponse = await State.getStateByCode(groupData.state);

      setCountry(countryResponse?.name ?? "Unknown Country");
      setState(stateResponse?.name ?? "Unknown State");
    })();
  }, []);
  // const dataStatsSeries = [
  // 	{
  // 		name: 'Energy Utilization',
  // 		data: groupData.utilization,
  // 	},
  // 	{
  // 		name: 'uptime',
  // 		data: groupData.uptime,
  // 	},
  // 	{
  // 		name: 'Charging session',
  // 		data: groupData.sessions,
  // 	},
  // ];

  return (
    <div className="mx-auto flex flex-1 items-center gap-4 rounded-md bg-gray-800 p-4 text-white shadow-md">
      <div className="">
        <div className="mb-2">
          <p className="font-bold text-gray-400">
            Group Name{" "}
            <span className="text-sm font-normal text-white">
              {groupData.name}
            </span>{" "}
          </p>
        </div>

        <div className="mb-2">
          <p className="font-bold text-gray-400">
            Address -{" "}
            <span className="text-sm font-normal text-white">
              {groupData.address}
            </span>{" "}
          </p>
        </div>

        <div className="mb-2">
          <p className="font-bold text-gray-400">
            City -{" "}
            <span className="text-sm font-normal text-white">
              {groupData.city}
            </span>
          </p>
        </div>

        <div className="mb-2">
          <p className="font-bold text-gray-400">
            Zip Code -{" "}
            <span className="text-sm font-normal text-white">
              {groupData.zip_code}
            </span>
          </p>
        </div>
        <div className="mb-2">
          <p className="font-bold text-gray-400">
            State -{" "}
            <span className="text-sm font-normal text-white">{state}</span>
          </p>
        </div>

        <div className="mb-2">
          <p className="font-bold text-gray-400">
            Country -{" "}
            <span className="text-sm font-normal text-white">{country}</span>
          </p>
        </div>
        {/* <div className="mb-2">
					<p className="text-gray-400 font-bold">Charger Status</p>
					<p className="text-sm">
						<MdPower className="inline-block text-green-400 mr-1" />
						Online: {groupData.chargerStatus.online} |
						<MdPowerOff className="inline-block text-red-400 mx-1" />
						Offline: {groupData.chargerStatus.offline} |
						<MdError className="inline-block text-yellow-400 ml-1" />
						Faulty: {groupData.chargerStatus.faulty}
					</p>
				</div>

				<div className="mb-2">
					<p className="text-gray-400 font-bold">Applied Tariff Plan</p>
					<p className="text-lg">{groupData.tariffPlan}</p>
				</div> */}
      </div>

      {/* <div className="mb-2">
				<div className="flex">
					<DeviceCharts
						series={dataStatsSeries}
						title="Graphs Uptime and session details"
					/>
				</div>
			</div> */}
    </div>
  );
};

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetChargeGroupsQuery } from "@/redux/features/charger-network/charger-network.api";
import {
  setChargeGroups,
  startLoading,
} from "@/redux/features/charger-network/charger-network.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useEffect, useState } from "react";

import { columns } from "./column";
import { GroupDataTable } from "./group-data-table";

export const GroupsTable: React.FC = () => {
  const { orgId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const {
    data: { chargeGroups: groups },
    loading,
  } = useAppSelector((state) => state.chargerNetwork);

  const [page, setPage] = useState(1);

  const { data, isFetching, isError } = useGetChargeGroupsQuery({
    orgId,
    page,
  });

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setChargeGroups(data));
    }
  }, [groups, dispatch, isFetching, data]);

  return (
    <div className="container rounded-lg border border-slate-400 bg-black p-6 text-white">
      <h2 className="text-2xl font-bold mb-4">Your Groups.</h2>

      <GroupDataTable
        loading={loading}
        data={groups ? groups.results : []}
        columns={columns}
      />

      {loading ? (
        <div className="my-2 flex justify-end">
          <div className="flex justify-end gap-2">
            <Skeleton className="h-6 w-12 rounded-sm" />
            <Skeleton className="h-6 w-12 rounded-sm" />
            <Skeleton className="h-6 w-12 rounded-sm" />
          </div>
        </div>
      ) : (
        data && (
          <Pagination className="justify-end pr-6 pt-4">
            <PaginationContent>
              {groups.previous && (
                <PaginationItem onClick={() => setPage(page - 1)}>
                  <PaginationPrevious href="#" />
                </PaginationItem>
              )}
              <PaginationItem>
                <PaginationLink href="#" isActive>
                  {page}
                </PaginationLink>
              </PaginationItem>
              {groups.next && (
                <PaginationItem onClick={() => setPage(page + 1)}>
                  <PaginationNext href="#" />
                </PaginationItem>
              )}
            </PaginationContent>
          </Pagination>
        )
      )}
    </div>
  );
};

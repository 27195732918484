import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useDeleteChargePointMutation } from "@/redux/features/charger-network/charger-network.api";
import type { ChargePointType } from "@/types/charger-network";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Row } from "@tanstack/react-table";
import { useState } from "react";
import { toast } from "sonner";

import { EditChargePoints } from "./edit-charge-points";

interface DataTableRowActionsProperties<TData> {
  row: Row<ChargePointType>;
}

export function Actions<TData>({ row }: DataTableRowActionsProperties<TData>) {
  const [deleteTariffPlansMutation, { isLoading }] =
    useDeleteChargePointMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const handleDelete = async (id: string) => {
    deleteTariffPlansMutation(id)
      .unwrap()
      .then(() => {
        toast.success("Charge point deleted successfully");
        setTimeout(() => {
          setIsModalOpen(false);
        }, 500);
      });
  };

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="z-10" asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem onClick={() => setIsEditOpen(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-red-500"
            onClick={() => setIsModalOpen(true)}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        modal
        open={isModalOpen}
        onOpenChange={() => setIsModalOpen(true)}
      >
        <DialogContent className="max-w-md p-4">
          <DialogHeader>
            <DialogTitle className="whitespace-nowrap text-center text-sm leading-8">
              Are you absolutely sure you want to remove this tariff plan?
            </DialogTitle>
            <DialogDescription className="text-center text-red-400">
              This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              size="sm"
              variant="outline"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="destructive"
              onClick={() => handleDelete(row.original?.id)}
              disabled={isLoading}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {isEditOpen && (
        <EditChargePoints
          editData={row.original}
          setIsEditOpen={setIsEditOpen}
        />
      )}
    </>
  );
}

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { formatDateToYYYYMMDD } from "@/lib/helpers/dates";
import { cn } from "@/lib/utils/cn";
import {
  useGetChargeGroupGlobalQuery,
  useGetChargeGroupsQuery,
  useUpdateChargePointMutation,
} from "@/redux/features/charger-network/charger-network.api";
import {
  incrementGlobalChargerGroupsPage,
  setChargeGroups,
  setChargePoints,
  startLoading,
} from "@/redux/features/charger-network/charger-network.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import type { ChargePointType } from "@/types/charger-network";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { ChartCircle } from "iconsax-react";
import { CalendarIcon, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

const FormSchema = z.object({
  name: z
    .string({ required_error: "Charge Point name cannot be empty" })
    .min(2, { message: "Charge Point name is too short" })
    .max(100, { message: "Charge Point name is too long" }),
  charge_point_id: z.string({
    required_error: "Charge Point ID cannot be empty",
  }),
  charger_make: z.string(),
  charger_model: z.string(),
  charger_type: z.string(),
  max_voltage: z.number(),
  max_amperage: z.number(),
  max_charging_power: z.number(),
  charger_group: z.string(),
  warranty_expiry_date: z.date(),
  serial_number: z.string(),
});

export function EditChargePoints({
  children,
  editData,
  setIsEditOpen,
}: {
  children?: React.ReactNode;
  editData?: ChargePointType;
  setIsEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { orgId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const globalChargeGroupsPage = useAppSelector(
    (state) => state.chargerNetwork.globalChargerGroups.page,
  );

  const {
    data: { chargeGroups: groups },
    // loading: groupsLoading,
  } = useAppSelector((state) => state.chargerNetwork);

  const {
    data,
    isFetching,
    isError,
    isLoading: groupsIsLoading,
  } = useGetChargeGroupsQuery({ orgId: orgId, page: globalChargeGroupsPage });
  const [editChargePointMutation, { isLoading }] =
    useUpdateChargePointMutation();

  const {
    data: groupsData,
    isLoading: groupsLoading,
    error: groupsError,
    isFetching: groupsFetching,
  } = useGetChargeGroupGlobalQuery({
    orgId: orgId,
    page: globalChargeGroupsPage,
  });

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: editData?.name,
      charge_point_id: editData?.charge_point_id,
      charger_make: editData?.charger_make,
      charger_model: editData?.charger_model,
      charger_type: editData?.charger_type,
      max_voltage: editData?.max_voltage,
      max_amperage: editData?.max_amperage,
      max_charging_power: editData?.max_charging_power,
      charger_group: editData?.charger_group?.id,
      warranty_expiry_date: editData?.warranty_expiry_date
        ? new Date(editData.warranty_expiry_date)
        : undefined,
      serial_number: editData?.serial_number,
    },
  });

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setChargeGroups(data));
    }
  }, []);

  const handleSubmit = async (values: z.infer<typeof FormSchema>) => {
    const initialExpiryDate = new Date(values.warranty_expiry_date);
    const formattedExpiryDate = formatDateToYYYYMMDD(initialExpiryDate);
    const result: any = {
      ...values,
      id: editData?.id,
      warranty_expiry_date: formattedExpiryDate,
    };

    editChargePointMutation(result)
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        dispatch(setChargePoints({ results: [...response.data] }));
        setIsEditOpen(false);
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(error.data.error_message);
      });
  };

  const loadMoreData = (data: any) => {
    if (data.next) {
      dispatch(incrementGlobalChargerGroupsPage());
    }
  };

  return (
    <Sheet modal open={isOpen} onOpenChange={() => setIsOpen(true)}>
      <SheetTrigger
        asChild
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {children}
      </SheetTrigger>
      <SheetContent className="overflow-scroll sm:max-w-lg">
        <div className="relative h-full w-full">
          {groupsLoading ? (
            <div className="absolute z-10 flex h-full w-full items-center justify-center bg-background p-4 text-2xl font-bold">
              Loading
            </div>
          ) : (
            <>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  <div className="flex w-full items-start justify-between">
                    <SheetHeader>
                      <SheetTitle>Add Charge Point</SheetTitle>
                      <SheetDescription>
                        Add Charge points to your charger groups
                      </SheetDescription>
                    </SheetHeader>
                    <X
                      onClick={() => {
                        form.reset();
                        setIsEditOpen(false);
                        setIsOpen(false);
                      }}
                      className="h-4 w-4"
                    />
                  </div>
                  {groupsData && groupsData.results.length === 0 && (
                    <div className="absolute z-10 flex h-full w-full items-center justify-center bg-background p-4 text-2xl font-bold">
                      Please create a charger group before creating charge
                      points
                    </div>
                  )}
                  {groupsData && groupsData.results.length > 0 && (
                    <div className="grid gap-4 py-4">
                      <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Name
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  placeholder="Name"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="charge_point_id"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                ID
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  placeholder="Charge Point ID"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="charger_make"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Charger Make
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  placeholder="Sample Make"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="charger_model"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Charger Model
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  placeholder="Sample Model"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="charger_type"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Charger Type
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="text"
                                  placeholder="Sample Type"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="max_voltage"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Max Voltage
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  placeholder="Voltage"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="max_amperage"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Max Amperage
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  placeholder="Amperage"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="max_charging_power"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Max Charging Power
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  placeholder="Charging Power"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="charger_group"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Charger Group
                              </FormLabel>

                              <div className="col-span-6">
                                <Select
                                  disabled={groupsLoading}
                                  onValueChange={(value) => {
                                    field.onChange(value);
                                  }}
                                  defaultValue={field.value}
                                >
                                  <FormControl>
                                    <SelectTrigger className="">
                                      <SelectValue
                                        placeholder={
                                          groupsLoading
                                            ? "Loading Groups"
                                            : "Groups"
                                        }
                                      />
                                    </SelectTrigger>
                                  </FormControl>
                                  <SelectContent>
                                    {groupsLoading ? (
                                      <div className="flex animate-spin justify-center py-2">
                                        <ChartCircle
                                          size="20"
                                          color="#FF8A65"
                                        />
                                      </div>
                                    ) : groupsData &&
                                      Object.keys(groupsData).length > 0 ? (
                                      <div>
                                        {groupsData &&
                                          groupsData.results.map(
                                            (group: any) => (
                                              <SelectItem
                                                value={group.id}
                                                key={group.name}
                                              >
                                                {group.name}
                                              </SelectItem>
                                            ),
                                          )}
                                        {groupsData &&
                                          groupsData.results.length > 0 &&
                                          !groupsFetching &&
                                          groupsData.next && (
                                            <button
                                              className="w-full py-2 text-center text-xs text-gray-500 hover:text-gray-300"
                                              onClick={() =>
                                                loadMoreData(groupsData)
                                              }
                                            >
                                              Load more
                                            </button>
                                          )}

                                        {groupsFetching && (
                                          <div className="flex animate-spin justify-center py-2">
                                            <ChartCircle
                                              size="20"
                                              color="#FF8A65"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div>No Charge groups found</div>
                                    )}
                                  </SelectContent>
                                </Select>
                              </div>
                            </FormItem>
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="warranty_expiry_date"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Waranty Expiry Date
                              </FormLabel>
                              <Popover>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant={"outline"}
                                      className={cn(
                                        "col-span-6 pl-3 text-left font-normal",
                                        !field.value && "text-muted-foreground",
                                      )}
                                    >
                                      {field.value ? (
                                        format(field.value, "PPP")
                                      ) : (
                                        <span>Pick a date</span>
                                      )}
                                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent
                                  className="w-auto p-0"
                                  align="start"
                                >
                                  <Calendar
                                    mode="single"
                                    selected={field.value}
                                    onSelect={field.onChange}
                                    disabled={(date) => date < new Date()}
                                    // initialFocus
                                  />
                                </PopoverContent>
                              </Popover>
                            </FormItem>
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="serial_number"
                        render={({ field }) => (
                          <>
                            <FormItem className="grid grid-cols-10 items-center gap-4">
                              <FormLabel className="col-span-4 whitespace-nowrap">
                                Serial Number
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  placeholder="Serial Number"
                                  className="col-span-6"
                                  {...field}
                                />
                              </FormControl>
                            </FormItem>{" "}
                            <FormMessage className="text-right text-[10px]" />
                          </>
                        )}
                      />
                    </div>
                  )}
                  <SheetFooter>
                    {groupsData && groupsData.results.length > 0 && (
                      <Button
                        disabled={isLoading}
                        variant="secondary"
                        type="submit"
                      >
                        Edit
                      </Button>
                    )}
                  </SheetFooter>
                </form>
              </Form>
            </>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}

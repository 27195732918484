import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { formatDateToYYYYMMDD } from "@/lib/helpers/dates";
import { useGetChargeGroupsQuery } from "@/redux/features/charger-network/charger-network.api";
import {
  setChargeGroups,
  startLoading,
} from "@/redux/features/charger-network/charger-network.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  exportTransactionRecordsCSV,
  exportTransactionRecordsPDF,
} from "@/services/revenue";
import type { ChargerGroupType } from "@/types/charger-network";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Table } from "@tanstack/react-table";
import { subDays } from "date-fns";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import type { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { AiOutlineExport } from "react-icons/ai";
import { z } from "zod";

import { ExportDate } from "./export-date";

interface ExportTransactionsProperties<TData> {
  table: Table<TData>;
}

const FormSchema = z.object({
  charger_groups: z.string(),
  pdf_export: z.boolean().default(true).optional(),
  csv_export: z.boolean().default(false).optional(),
});

const items = [
  {
    id: "csv",
    label: "CSV",
  },
  {
    id: "pdf",
    label: "PDF",
  },
] as const;
export function ExportTransactions<TData>({
  table,
}: ExportTransactionsProperties<TData>) {
  const { orgId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedExportFormat, setSelectedExportFormat] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    data: { chargeGroups: groups },
  } = useAppSelector((state) => state.chargerNetwork);
  const {
    data: groupsData,
    isFetching,
    isError,
    isLoading,
  } = useGetChargeGroupsQuery(
    { orgId },
    {
      skip: !!groups,
    },
  );

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedInterval, setSelectedInterval] = useState<string>("custom");
  const [date, setDate] = useState<DateRange | any>();

  useEffect(() => {
    hanleDateChange(date);
  }, [date]);

  const hanleDateChange = (dateResult?: DateRange | any, value?: any) => {
    if (dateResult) {
      const initialFromDate = new Date(dateResult.from);
      const fromDate = formatDateToYYYYMMDD(initialFromDate);
      const initialToDate = new Date(dateResult.to);
      const toDate = formatDateToYYYYMMDD(initialToDate);

      return { fromDate, toDate };
    }
  };

  const handleExport = async (values: {
    charger_groups: string;
    csv_export?: boolean;
    pdf_export?: boolean;
  }) => {
    const dateData = hanleDateChange(date);
    if (values.csv_export && dateData) {
      setIsDownloading(true);
      try {
        const response: any = await exportTransactionRecordsCSV({
          charger_groups_id: values.charger_groups,
          fromDate: dateData.fromDate,
          toDate: dateData.toDate,
        });

        const blob = new Blob([response], { type: "text/csv" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and click it to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "transactions.csv");
        document.body.append(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        throw new Error(error);
      } finally {
        setIsDownloading(false);
      }
    }

    if (values.pdf_export && dateData) {
      setIsDownloading(true);
      try {
        const response: any = await exportTransactionRecordsPDF({
          charger_groups_id: values.charger_groups,
          fromDate: dateData.fromDate,
          toDate: dateData.toDate,
        });

        const blob = new Blob([response], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and click it to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "transactions.pdf");
        document.body.append(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        throw new Error(error);
      } finally {
        setIsDownloading(false);
      }
    }
  };

  const handleDateFilter = (interval: string) => {
    const today = new Date();
    setSelectedInterval(interval);
    switch (interval) {
      case "lastMonth": {
        setDate({
          from: subDays(today, 30),
          to: today,
        });
        break;
      }
      case "last2Months": {
        setDate({
          from: subDays(today, 60),
          to: today,
        });
        break;
      }
      case "custom": {
        // Set custom logic for the 'Custom' interval
        // For example, you can open a date picker modal
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (groupsData) {
      dispatch(setChargeGroups(groupsData));
    }
  }, [dispatch, isFetching, groupsData]);

  return (
    <div className="flex justify-evenly gap-2">
      <Button variant="secondary" onClick={() => setIsModalOpen(true)}>
        <AiOutlineExport className="mr-2 h-4 w-4" />
        Export
      </Button>
      <Dialog
        modal
        open={isModalOpen}
        onOpenChange={() => setIsModalOpen(true)}
      >
        <DialogContent className="max-h-xl max-w-md border-0 bg-background text-white shadow-2xl">
          <DialogHeader className="border-b border-b-gray-500 p-4">
            <DialogTitle className="flex items-center justify-between">
              <h2>Export Transactions.</h2>
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="secondary"
                type="button"
              >
                <X size={16} color="black" />
              </Button>
            </DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form className="p-4" onSubmit={form.handleSubmit(handleExport)}>
              <div className="space-y-3">
                <div>Time frame</div>
                <div className="flex justify-between gap-4">
                  <Button
                    type="button"
                    variant={
                      selectedInterval === "lastMonth" ? "secondary" : "default"
                    }
                    onClick={() => handleDateFilter("lastMonth")}
                  >
                    Last month
                  </Button>
                  <Button
                    type="button"
                    variant={
                      selectedInterval === "last2Months"
                        ? "secondary"
                        : "default"
                    }
                    onClick={() => handleDateFilter("last2Months")}
                  >
                    Last 2 months
                  </Button>
                  <Button
                    type="button"
                    variant={
                      selectedInterval === "custom" ? "secondary" : "default"
                    }
                    onClick={() => handleDateFilter("custom")}
                  >
                    Custom
                  </Button>
                </div>
                {selectedInterval === "custom" && (
                  <ExportDate onDateChange={setDate} />
                )}

                <div className="space-y-3">
                  <div>Charger groups</div>

                  <FormField
                    control={form.control}
                    name="charger_groups"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <div className="flex items-center gap-3">
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                            }}
                            defaultValue={"All"}
                          >
                            <FormControl>
                              <SelectTrigger className="w-full">
                                <SelectValue />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectGroup>
                                {Object.keys(groups).length > 0 &&
                                  groups.results.map(
                                    (group: ChargerGroupType) => (
                                      <SelectItem
                                        key={group.id}
                                        value={group.id}
                                      >
                                        {group.name}
                                      </SelectItem>
                                    ),
                                  )}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        <FormMessage className="text-[10px]" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <div>
                    <h2 className=" ">Export Format</h2>
                    <p className="text-xs text-gray-500">
                      Select the format you wish to export your transactions.
                    </p>
                  </div>
                  <FormField
                    control={form.control}
                    name="pdf_export"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">PDF</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={form.control}
                    name="csv_export"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">CSV</FormLabel>
                        </FormItem>
                      );
                    }}
                  />{" "}
                  {/* <FormLabel className="text-base">Export Format</FormLabel>
									<FormDescription>
										Select the format you wish to export your transactions.
									</FormDescription> */}
                </div>
              </div>

              <div className="flex justify-end gap-3 pt-8">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button>
                  {isDownloading ? "Exporting..." : "Export Record"}
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

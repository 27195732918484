import { formatDate } from "@/lib/helpers/dates";
import { faker } from "@faker-js/faker";
import type { RFIDCardType } from "./schema";
import { driverRoles } from "../../data";

export const generateRFIDCards = (count: number): RFIDCardType[] => {
  const rfidCards = [];

  for (let index = 0; index < count; index++) {
    const rfidCard = {
      uid: `RFID-${faker.number.int({ min: 10, max: 100 })}`,
      name: faker.person.fullName(),
      accessProfile: faker.helpers.arrayElement(driverRoles).value,
      expirationDate: formatDate(faker.date.recent().toString()),
    };
    rfidCards.push(rfidCard);
  }
  return rfidCards;
};

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type Properties = {};

function BillingAddress({}: Properties) {
  return (
    <div className="overflow-hidden rounded-lg border-[.3px] border-gray-500 bg-primary text-white">
      <div className="space-y-2 p-4">
        <h2 className="text-lg font-bold">Billing Address</h2>
        <p className="text-sm">
          If you’d like to add a postal address to every invoice, enter it here.
        </p>
        <div className="space-y-3 md:w-[50%]">
          <Input placeholder="Address line 1" className="" />
          <Input placeholder="Address line 2" className="" />
          <div className="flex gap-3">
            <Select>
              <SelectTrigger className="flex-1">
                <SelectValue placeholder="State" />
              </SelectTrigger>
              <SelectContent>
                {/** Fill up states from the library here ~ <SelectItem value="state">Owner</SelectItem>  */}
              </SelectContent>
            </Select>
            <Input placeholder="Zip/Postal code" className="flex-1" />
          </div>

          <Select>
            <SelectTrigger className="flex-1">
              <SelectValue placeholder="Country" />
            </SelectTrigger>
            <SelectContent>
              {/** Fill up countries from the library here ~ <SelectItem value="country">Owner</SelectItem> */}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex items-center justify-between border-t-[.3px] border-gray-500 bg-primary p-4">
        <span className="text-xs">
          Feel free to fill out only the fields that you want to fill out.
        </span>
        <Button variant="secondary">Add new card method</Button>
      </div>
    </div>
  );
}

export default BillingAddress;

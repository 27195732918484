import { Sidebar } from "@/components/sidebar";
import {
  extractCountryCode,
  extractCurrencySymbol,
} from "@/lib/helpers/country-code-currency";
import { useGetUserProfileQuery } from "@/redux/features/auth/auth.api";
import {
  setOrgId,
  setUser,
  startLoading,
} from "@/redux/features/auth/auth.slice";
import { setGlobalCurrency } from "@/redux/features/home/home.slice";
import { useGetOrganizationsQuery } from "@/redux/features/org/org.api";
import { setOrganisations } from "@/redux/features/org/org.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import type React from "react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export const Main = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => !!state.auth.user);
  const { orgId, user } = useAppSelector((state) => state.auth);
  const { currentOrg } = useAppSelector((state) => state.org);
  const { loading } = useAppSelector((state) => state.home);

  const [dataState, setDataState] = useState("close");

  const { data: organizationsData, isLoading: organiszationsLoading } =
    useGetOrganizationsQuery(undefined);
  const { data, isLoading } = useGetUserProfileQuery(undefined, {
    skip: !user,
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setUser(data));
    }

    if (organiszationsLoading) {
      dispatch(startLoading());
    } else if (organizationsData) {
      dispatch(
        setOrgId(
          organizationsData.data.find(
            (data: Org) => data.org_type === "Private",
          )?.id,
        ),
      );
      dispatch(setOrganisations(organizationsData.data));
    }
  }, [
    data,
    isLoading,
    organiszationsLoading,
    organiszationsLoading,
    dispatch,
    organizationsData,
  ]);

  useEffect(() => {
    if (currentOrg) {
      const countryCode = extractCountryCode(currentOrg.org_phone_number);
      if (countryCode) {
        const currencySymbol = extractCurrencySymbol(countryCode);
        dispatch(setGlobalCurrency(currencySymbol));
      } else {
        dispatch(setGlobalCurrency("$"));
      }
    }
  }, [currentOrg, extractCountryCode, extractCurrencySymbol, dispatch]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div
      data-state={dataState}
      className="flex h-full w-full flex-col pb-[90px] transition-all data-[state=close]:pl-14 md:pb-0 md:pl-[260px]"
    >
      <Sidebar
        className="overflow-y-scroll"
        dataState={dataState}
        setDataState={setDataState}
      />

      <div className="scrollbar-thin scrollbar-thumb-gray-900 flex-1 overflow-y-scroll">
        {children}
      </div>
    </div>
  );
};

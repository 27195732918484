import request from "@/lib/utils/axios";
import type {
  AddChargePointType,
  AddChargerGroupType,
} from "@/types/charger-network";
import { AxiosError } from "axios";

const orgId = localStorage.getItem("org_id");
export const addChargerGroup = async (value: AddChargerGroupType) => {
  try {
    const res = await request.post(`/core_backend/charger-groups/`, value);
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const addChargePoint = async (value: AddChargePointType) => {
  try {
    const res = await request.post(`/core_backend/new-chargepoint/`, value);
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getChargerGroups = async () => {
  try {
    const res = await request.get(
      `/core_backend/charger-groups/?organization=${orgId}`,
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getChargerPointsById = async (values: { charger_id: string }) => {
  try {
    const res = await request.get(
      `/core_backend/charger-groups/${values.charger_id}/?organization=${orgId}`,
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};
export const getChargePoints = async () => {
  try {
    const res = await request.get(`/core_backend/chargepoints/${orgId}`);
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getChargersState = async (values?: { group_id: string }) => {
  try {
    const res = await request.get(
      `/core_backend/utilization-breakdown/${orgId}${
        values && `?charger_group_id=${values.group_id}`
      }`,
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};

export const getChargersUtilizationRate = async (values?: {
  group_id: string | null;
}) => {
  try {
    const res = await request.get(
      `/core_backend/daily-charger-utilization/${orgId}${
        values?.group_id ? `?charger_group=${values.group_id}` : ""
      }`,
    );
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (error.response) {
        return error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  }
};
